<script setup lang="ts"></script>
<template>
   <div class="min-h-dvh flex flex-col justify-between">
      <div class="flex flex-col items-center">
         <div class="container px-4 lg:px-14">
            <LayoutUnAuthHeader></LayoutUnAuthHeader>
            <div class="flex justify-center w-full my-2 md:my-10">
               <div class="sm-w-full md:w-3/4 xl:w-1/2">
                  <RegisterSteps />

                  <NuxtPage />
               </div>
            </div>
         </div>
      </div>
      <LayoutFooter></LayoutFooter>
      <WhatappContact :is-right="true"></WhatappContact>
   </div>
</template>
